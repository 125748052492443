 import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AgentContext } from '../contexts/AgentContext';

const LoginPage = ({ onLogin }) => {
    const [agentId, setAgentId] = useState('');
    const [tenantId, setTenantId] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const { setAgentData } = useContext(AgentContext);
    const [autoLogin, setAutoLogin] = useState(false);

    useEffect(() => {
        function handleMessage(event) {
            const { agentId, tenantId } = event.data;
            console.log(agentId, tenantId);
            setAgentId(agentId);
            setTenantId(tenantId);

            setAutoLogin(true);
        }

        if (window.self !== window.top) {
            // Page is loaded inside an iframe
            window.addEventListener('message', handleMessage);

            return () => {
                window.removeEventListener('message', handleMessage);
            };
        }
    }, []);

    useEffect(() => {
        if (agentId && tenantId && autoLogin) {
            handleLogin(); // Call handleLogin directly without event parameter
        }
    }, [agentId, tenantId, autoLogin]);

    const handleLogin = async (event) => {
        if (event) {
            event.preventDefault();
        }
        setError('');
        if (!agentId || !tenantId) {
            setError('All fields are required');
            return;
        }
        try {
            const response = await fetch('https://squawk-ob-survey-vs3qzutl6a-ue.a.run.app/api/v1/agents/doAgentLogin', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'tenantId': tenantId,
                    'api-key': "c3d81b1f-cd65-43e2-bc38-5f410c70cb02"
                },
                body: JSON.stringify({ id: agentId }),
            });

            if (response.ok) {
                const result = await response.json();
                if (result) {
                    onLogin(agentId);
                    setAgentData(result);
                    localStorage.setItem('agentData', JSON.stringify(result));
                    localStorage.setItem('tenantId', tenantId);
                    localStorage.setItem('apiKey', "c3d81b1f-cd65-43e2-bc38-5f410c70cb02");
                    navigate('/dashboard');
                } else {
                    setError('Login failed');
                }
            } else {
                if (response.status === 404) {
                    setError('Agent not found');
                } else {
                    setError('Error logging in');
                }
            }
        } catch (error) {
            setError('Failed to connect. Please check your internet connection.');
        }
    };

    const labelStyle = { fontWeight: 'bold' , marginRight: '10px', width: '30%', fontSize: '14px' };
    const buttonStyle = { padding: '10px 20px', backgroundColor: '#007BFF', color: '#fff', border: 'none', borderRadius: '4px', cursor: 'pointer', fontSize: '16px' };

    return (
        <div style={{ fontFamily: 'Encode Sans, sans-serif', height: '100vh', display: 'flex', flexDirection: 'column' }}>
            <div style={{ backgroundColor: '#3979F5', padding: '10px', color: '#fff', fontWeight: 500, fontSize: '20px' }}>
                WeCare Connect
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexGrow: 1 }}>
                <form onSubmit={handleLogin} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', backgroundColor: '#fff', padding: '20px', borderRadius: '8px', boxShadow: '0px 0px 10px rgba(0,0,0,0.1)', width: '80%', maxWidth: '400px' }}>
                    <h2 style={{ marginBottom: '20px', textAlign: 'center', width: '100%', fontWeight: 'normal' }}>Agent Login</h2>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px', marginTop: '10px', width: '100%' }}>
                        <label style={labelStyle}>Agent ID</label>
                        <input
                            type="text"
                            value={agentId}
                            onChange={(e) => setAgentId(e.target.value)}
                            required
                            style={{ padding: '10px', width: '70%', borderRadius: '4px', border: '1px solid #ccc', fontFamily: 'Encode Sans, sans-serif' }}
                        />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px', width: '100%' }}>
                        <label style={labelStyle}>Tenant ID</label>
                        <input
                            type="text"
                            value={tenantId}
                            onChange={(e) => setTenantId(e.target.value)}
                            required
                            style={{ padding: '10px', width: '70%', borderRadius: '4px', border: '1px solid #ccc', fontFamily: 'Encode Sans, sans-serif' }}
                        />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px', width: '100%' }}>
                        <button type="submit" style={buttonStyle}>Login &nbsp; <i class="fa-solid fa-angle-right"></i></button>
                    </div>
                    {error && <p style={{ color: 'red', marginTop: '20px', textAlign: 'center', width: '100%' }}>{error}</p>}
                </form>
            </div>
        </div>
    );
};

export default LoginPage;