import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoginPage from './components/LoginPage';
import Dashboard from './components/Dashboard';
import { AgentContext } from './contexts/AgentContext';
import './App.css';

function App() {
    const [agentData, setAgentData] = useState(null);

    const handleLogin = (data) => {
        setAgentData(data);
    };

    return (
        <AgentContext.Provider value={{ agentData, setAgentData }}>
            <Router>
                <Routes>
                    <Route path="/" element={<LoginPage onLogin={handleLogin} />} />
                    <Route path="/dashboard" element={<Dashboard />} />
                </Routes>
            </Router>
        </AgentContext.Provider>
    );
}

export default App;