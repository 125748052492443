// firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyAHNUwJ0QP2rWYh4oUWm1f1EbDRCEGZasg",
  authDomain: "squawk-ob-survey.firebaseapp.com",
  projectId: "squawk-ob-survey",
  storageBucket: "squawk-ob-survey.appspot.com",
  messagingSenderId: "772076788334",
  appId: "1:772076788334:web:a32c56e081decc7074247f",
  measurementId: "G-86TM8KH2GP"
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const db = getFirestore(app);